import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Switch } from "../../../components/ui/switch";
import { User } from "../../../models/user.model";

interface OrganizationDetailsTabProps {
  user: User;
}
const OrganizationDetailsTab: React.FC<OrganizationDetailsTabProps> = ({
  user,
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Organization Details</CardTitle>
        <p>Your organization information.</p>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <Label>Organization ID</Label>
          <Input value={user.organization?.id || ""} disabled />
        </div>
        <div className="flex items-center space-x-2">
          <Switch id="isOrgOwner" checked={user.organization?.isOrgOwner} />
          <Label htmlFor="isOrgOwner">Organization Owner</Label>
        </div>
      </CardContent>
    </Card>
  );
};

export default OrganizationDetailsTab;
