import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Event, EventGroup } from "../models/event.model";
import { Product } from "../models/product.model";
import { TnSellListing } from "../models/resell.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Events {
  data: Event[];
  skip: number;
  take: number;
  count: number;
}

interface EventGroups {
  data: EventGroup[];
  skip: number;
  take: number;
  count: number;
}

interface Query {
  skip?: number;
  take?: number;
  search?: string;
}

interface Products {
  data: Product[];
  skip: number;
  take: number;
  count: number;
}
interface PublicEventsBuyerRequestResponse {
  data: TnSellListing[];
  skip: number | undefined;
  take: number | undefined;
  count: number;
}

interface ProductsQuery {
  skip?: number;
  take?: number;
  search?: string;
  organizationId?: string;
  eventId?: string;
  isSystemOwned?: boolean;
}

export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/public",
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Events", "Event", "Products", "Product"],
  endpoints: (builder) => ({
    getEvents: builder.query<Events, Query>({
      query: ({ take, skip, search }) => {
        return {
          url: "/events",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Events"],
    }),
    getEventGroups: builder.query<EventGroups, Query>({
      query: ({ take, skip, search }) => {
        return {
          url: "/event-groups",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Events"],
    }),
    getEventGroup: builder.query<EventGroup, string>({
      query: (id) => ({
        url: `/event-groups/${id}`,
      }),
      providesTags: ["Event"],
    }),
    getEvent: builder.query<Event, string>({
      query: (id) => ({
        url: `/events/${id}`,
      }),
      providesTags: ["Event"],
    }),
    getEventBuyerRequest: builder.query<
      PublicEventsBuyerRequestResponse,
      {
        id: string;
        skip?: number;
        take?: number;
        search?: string;
        sortByDate?: "asc" | "desc";
        userId: string;
      }
    >({
      query: ({
        id,
        skip = 0,
        take = 10,
        search = "",
        sortByDate = "asc",
        userId,
      }) => {
        const params = new URLSearchParams({
          skip: skip.toString(),
          take: take.toString(),
          search,
          sortByDate,
          userId, // Add userId to the query parameters
        }).toString();

        return {
          url: `/events/${id}/buyer-requests?${params}`,
        };
      },
      providesTags: ["Event"],
    }),

    getProducts: builder.query<Products, ProductsQuery>({
      query: ({
        search,
        skip,
        take,
        eventId,
        organizationId,
        isSystemOwned,
      }) => ({
        url: `/products`,
        params: {
          skip,
          take,
          search,
          eventId,
          organizationId,
          isSystemOwned,
        },
      }),
      providesTags: ["Products"],
    }),

    getProduct: builder.query<Product, string>({
      query: (id) => ({
        url: `/products/${id}`,
      }),
      providesTags: ["Products"],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetEventsQuery,
  useGetEventGroupsQuery,
  useGetEventGroupQuery,
  useGetProductsQuery,
  useGetProductQuery,
  useGetEventBuyerRequestQuery,
} = eventApi;
