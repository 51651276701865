import React from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import Footer from "./components/layout/Footer";
import "./css/clash-display.css";
import { authSelect, setUser } from "./features/Authentication/authSlice";
import { BecomePartner, SelectedEventGroupPage } from "./pages";
import HomePage from "./pages/Home";
import ProfilePage from "./pages/Profile";
import SearchPage from "./pages/Search";
import SelectedEventPage from "./pages/SelectedEvent";
import SignInPage from "./pages/SignIn";
import SignUpPage from "./pages/SignUp";
import TicketSummaryPage from "./pages/TicketSummary";
import { useRefreshMutation } from "./services/auth.api";
// import PaymentPage from "./pages/Payment";
// import SuccessPage from "./pages/Payment/Gcash/Success";
// import FailedPage from "./pages/Payment/Gcash/Failed";
import CookieConsent from "react-cookie-consent";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/layout/Navbar";
import Loading from "./components/Loading";
import Product from "./features/Event/SelectedEvent/Shop/Product/Product";
import Shop from "./features/Event/SelectedEvent/Shop/Shop";
import ArtistPage from "./pages/Artist";
import GroupDetailsPage from "./pages/Artist/GroupDetails";
import PerformerDetails from "./pages/Artist/PerformerDetails";
import { AuthCallbackPage } from "./pages/Callback/AuthCallbackPage";
import ComingSoon from "./pages/ComingSoon";
import AboutUs from "./pages/ComingSoon/AboutUs";
import Privacy from "./pages/ComingSoon/Privacy";
import SplashScreen from "./pages/ComingSoon/SplashScreen";
import WhatWeDo from "./pages/ComingSoon/WhatWeDo";
import DownloadPage from "./pages/Download";
import Error404Page from "./pages/Error404";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ForgotPassword/ResetPassword";
import PasswordResetSuccess from "./pages/ForgotPassword/Success";
import Maintenance from "./pages/Maintenance";
import MarketingPage from "./pages/Marketing";
import OrderPage from "./pages/Order";
import PrivacyPage from "./pages/Policy/Privacy";
import { TermsPage } from "./pages/Policy/Terms";
import ProfileListings from "./pages/Profile/ProfileListings";
import ProfileOrder from "./pages/Profile/ProfileOrder";
import ProfileSales from "./pages/Profile/ProfileSales";
import ProfileSettings from "./pages/Profile/ProfileSettings";
import Redirect from "./pages/Redirect";
import ArtistSearchPage from "./pages/Search/ArtistSearch";
import SuccessSignup from "./pages/SignUp/Success";
import ErrorPayment from "./pages/TicketSummary/ErrorPayment";
import Success from "./pages/TicketSummary/Success";
import VerificationPage from "./pages/Verification";
import VerificationSuccess from "./pages/Verification/Success";
import { useGetHealthQuery } from "./services/health.api";

interface ProtectedRouteProps {
  isAllowed: boolean;
  waitForAuth?: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
}

interface LocationState {
  from?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectPath = "/",
  children,
  waitForAuth = false,
}): any => {
  const location = useLocation();

  if (!isAllowed) {
    if (waitForAuth) {
      return null;
    } else {
      return <Navigate to={redirectPath} replace state={{ from: location }} />;
    }
  }
  return children ? children : <Outlet />;
};

const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as LocationState;
  const { user } = useAppSelector(authSelect);
  const [refresh, { data, isSuccess, isLoading, isError }] =
    useRefreshMutation();
  const {
    isSuccess: isHealthSuccess,
    isLoading: isHealthLoading,
    isError: isHealthError,
  } = useGetHealthQuery({});

  React.useEffect(() => {
    document.querySelector("html")?.setAttribute("data-theme", "corporate");
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("refreshToken")) refresh({});
  }, [refresh]);

  React.useEffect(() => {
    if (isError) {
      localStorage.removeItem("refreshToken");
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );
      localStorage.setItem("refreshToken", data.refreshToken);
    }
  }, [isSuccess, isLoading, data, dispatch]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <div
      id="app-container"
      className="flex flex-col h-full min-h-screen"
      style={{ fontFamily: "Inter" }}
    >
      {isLoading || isHealthLoading ? (
        <Loading />
      ) : (
        <Routes>
          {!isHealthSuccess && isHealthError ? (
            <Route path="/" element={<Maintenance />} />
          ) : (
            <>
              {process.env.REACT_APP_DEVELOPMENT === "false" ||
              process.env.REACT_APP_DEVELOPMENT === undefined ? (
                <>
                  <Route path="/" element={<ComingSoon />} />
                  <Route path="/splash-screen" element={<SplashScreen />} />
                  <Route path="/what-we-do" element={<WhatWeDo />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/privacy-policy" element={<Privacy />} />
                </>
              ) : (
                <>
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={user === null}
                        redirectPath={state?.from || "/"}
                      />
                    }
                  >
                    <Route path="/signin" element={<SignInPage />} />
                    <Route path="signup">
                      <Route index element={<SignUpPage />} />
                      <Route path="success" element={<SuccessSignup />} />
                    </Route>
                  </Route>
                  <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                  />
                  <Route path="reset-password">
                    <Route index element={<ResetPasswordPage />} />
                    <Route path="success" element={<PasswordResetSuccess />} />
                    <Route path="*" element={<Error404Page />} />
                  </Route>
                  <Route
                    element={
                      <div
                        id="main-container"
                        className="relative flex flex-col min-h-screen"
                      >
                        <Navbar />
                        <div className="flex flex-col flex-grow ">
                          <div
                            id="outlet-container"
                            className="flex flex-col flex-1 flex-grow w-full h-full mx-auto max-w-7xl"
                          >
                            <Outlet />
                          </div>
                        </div>
                        <Footer />
                      </div>
                    }
                  >
                    <Route path="/" element={<HomePage />} />
                    <Route path="artist">
                      <Route
                        path=":id/performer"
                        element={<PerformerDetails />}
                      />
                      <Route path=":id/group" element={<GroupDetailsPage />} />
                      <Route index element={<ArtistPage />} />
                    </Route>

                    {/* <Route path="/about-us" element={<AboutUsPage />} /> */}
                    <Route path="/about-us" element={<MarketingPage />} />
                    <Route path="/download" element={<DownloadPage />} />
                    <Route path="event">
                      <Route path=":id">
                        <Route index element={<SelectedEventPage />} />

                        <Route
                          path="order/:orderId"
                          element={
                            <ProtectedRoute
                              isAllowed={user !== null}
                              redirectPath={state?.from || "/"}
                              waitForAuth
                            />
                          }
                        >
                          <Route element={<OrderPage />}>
                            <Route index element={<TicketSummaryPage />} />
                            <Route path="shop">
                              <Route index element={<Shop />} />
                              <Route path="products">
                                <Route index element={<Product />} />
                              </Route>
                            </Route>
                          </Route>
                          <Route path="success" element={<Success />} />
                          <Route path="cancel" element={<ErrorPayment />} />
                          <Route path="*" element={<Error404Page />} />
                        </Route>
                      </Route>
                      <Route path="*" element={<Error404Page />} />
                    </Route>

                    {/* event group */}
                    <Route path="event-group">
                      <Route path=":id">
                        <Route index element={<SelectedEventGroupPage />} />
                        <Route
                          path="order/:orderId"
                          element={
                            <ProtectedRoute
                              isAllowed={user !== null}
                              redirectPath={state?.from || "/"}
                              waitForAuth
                            />
                          }
                        >
                          <Route index element={<TicketSummaryPage />} />
                          <Route path="success" element={<Success />} />
                          <Route path="cancel" element={<ErrorPayment />} />
                          <Route path="*" element={<Error404Page />} />
                        </Route>
                      </Route>
                      <Route path="*" element={<Error404Page />} />
                    </Route>
                    {/* end event group */}
                    <Route path="/search" element={<SearchPage />} />
                    <Route
                      path="/artist/search"
                      element={<ArtistSearchPage />}
                    />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route
                      path="/auth/callback"
                      element={<AuthCallbackPage />}
                    />
                    <Route path="partner">
                      <Route index element={<BecomePartner />} />
                    </Route>
                    <Route path="verification">
                      <Route index element={<VerificationPage />} />
                      <Route path="success" element={<VerificationSuccess />} />
                      <Route path="*" element={<Error404Page />} />
                    </Route>
                    <Route
                      element={
                        <ProtectedRoute
                          isAllowed={user !== null}
                          redirectPath="/signin"
                        />
                      }
                    >
                      <Route path="profile" element={<ProfilePage />}>
                        <Route index element={<ProfileSettings />} />
                        <Route path="listings" element={<ProfileListings />} />
                        <Route path="orders" element={<ProfileOrder />} />
                        <Route path="sales" element={<ProfileSales />} />
                      </Route>
                    </Route>
                    <Route path="redirect" element={<Redirect />} />
                    <Route path="*" element={<Error404Page />} />
                  </Route>
                </>
              )}
            </>
          )}
        </Routes>
      )}
      <ToastContainer />
      {/* <MessengerChat /> */}
      <CookieConsent
        buttonText="I Understand"
        containerClasses="bg-blue-900 bg-opacity-70"
        declineButtonClasses="rounded-lg"
        buttonClasses="rounded-lg bg-white text-blue-900 "
        enableDeclineButton
        declineButtonText="I Decline"
        onDecline={() => {
          window.history.back();
        }}
        cookieName="isCookieAccepted"
      >
        Ticketnation.ph uses cookies to enhance the user experience. View our
        <Link to="/privacy" className="ml-1 font-medium underline">
          Privacy Policy.
        </Link>
      </CookieConsent>
    </div>
  );
};

export default App;
