import { Performer } from "../models/performer.model";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Group } from "../models/group.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Artists {
  data: Performer[];
  skip: number;
  take: number;
  count: number;
}

interface Groups {
  data: Group[];
  skip: number;
  take: number;
  count: number;
}

interface ArtistsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const artistApi = createApi({
  reducerPath: "artistApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/public",
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Artists", "Artist", "Groups"],
  endpoints: (builder) => ({
    getArtists: builder.query<Artists, ArtistsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/artists",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Artists"],
    }),
    getArtist: builder.query<Performer, string>({
      query: (id) => ({
        url: `/artists/${id}`,
      }),
      providesTags: ["Artist"],
    }),
    getGroups: builder.query<Groups, ArtistsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/groups",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Groups"],
    }),
    getGroup: builder.query<Group, string>({
      query: (id) => ({
        url: `/groups/${id}`,
      }),
      providesTags: ["Artist"],
    }),
  }),
});

export const {
  useGetArtistQuery,
  useGetArtistsQuery,
  useGetGroupsQuery,
  useGetGroupQuery,
} = artistApi;
