import { OtpVerification } from "@/pages/OtpEmail/context/OrderContextTypes";
import {
  BaseQueryFn,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

const TICKETNATION_API_URL =
  process.env.NEXT_PUBLIC_TICKETNATION_API_URL || "https://api.ticketnation.ph";

export const emailOtpApi = createApi({
  reducerPath: "emailOtpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: TICKETNATION_API_URL,
  }) as BaseQueryFn<any, unknown, FetchBaseQueryError>,
  tagTypes: ["EmailOtp"],
  endpoints: (builder) => ({
    getEmailOtp: builder.query<OtpVerification | null, string>({
      query: (otpId) => `/email-otp/${otpId}`,
      providesTags: ["EmailOtp"],
    }),
  }),
});

export const { useGetEmailOtpQuery } = emailOtpApi;
