import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import { CreateUserOrganization, UpdateUser, User } from "../models/user.model";
import { Voucher } from "./../models/voucher.model";

export interface CustomError {
  data: {
    message: string;
  };
  status: number;
}

export interface ValidateVoucher {
  encodedCode: string;
  eventId: string;
}

export const userApi = createApi({
  reducerPath: "userApi",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/user",
    prepareHeaders: (headers, { getState, endpoint }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  endpoints: (builder) => ({
    createUserOrganization: builder.mutation<unknown, CreateUserOrganization>({
      query: (body) => ({
        url: "organization-create",
        method: "POST",
        body,
      }),
    }),
    voucherValidate: builder.query<Voucher, ValidateVoucher>({
      query: (data) => ({
        url: `event/${data.eventId}/voucher-validate/${data.encodedCode}`,
        method: "GET",
      }),
    }),
    updateUser: builder.mutation<{ email: string }, UpdateUser>({
      query: (body) => ({
        url: "profile",
        method: "PATCH",
        body,
      }),
    }),
    getUser: builder.query<User, unknown>({
      query: () => ({
        url: "/",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateUserOrganizationMutation,
  useUpdateUserMutation,
  useLazyVoucherValidateQuery,
  useGetUserQuery,
} = userApi;
